<template>
  <section class="testimonial-section pb-5">
    <div class="container">
      <div class="testimonials-slider-container position-relative mb-5">
        <div class="testimonials-slider tiny-slider">
          <div class="item" v-for="item in itens" :key="item.id">
            <FormacoesRecentes v-bind="item" />
          </div>
        </div><!--//testimonials-slider-->
      </div><!--//slider-container-->
      <div class="pt-5 text-center">
        <router-link class="btn btn-light"
        to="areas">Consulte o catálogo<i class="fas fa-arrow-alt-circle-right ms-2"></i></router-link>
      </div>
    </div><!--//container-->
  </section><!--//testiomonial-section-->
</template>

<script>

import { tns } from "tiny-slider/src/tiny-slider";
import FormacoesRecentes from './testimonials/FormacoesRecentes.vue';
import DataFacade from "@/services/DataFacade";
const facade = new DataFacade();
export default {
  name: "TestimonialSection",
  created() { },
  data() {
    return {
      itens: facade.getData('LocalData', 'formacoesRecentes')
    };
  },
  props: {},
  methods: {},
  components: { FormacoesRecentes },
  mounted() {

    const slider = tns({
      container: '.testimonials-slider',
      loop: true,
      items: 3,
      responsive: {
        0: {
          items: 1
        },
        576: {
          items: 2
        },
        992: {
          items: 3
        },
      },
      slideBy: 'page',
      nav: true,
      autoplay: true,
      autoplayButtonOutput: false,
      mouseDrag: true,
      lazyload: true,
      gutter: 15,
      navPosition: 'bottom',
      controls: false,
      speed: 800,

    });

  }
};

</script>

