<script setup>
import HeaderMain from '@/components/general/HeaderMain.vue';
import LogoSection from '@/components/general/LogoSection.vue';
import TestimonialSection from '@/components/general/TestimonialSection.vue';
import HowSection from '@/components/general/HowSection.vue';
import IntegrationSection from '@/components/general/IntegrationSection.vue';
import CtaSection from '@/components/general/CtaSection.vue';
import FooterSection from '@/components/general/FooterSection.vue';
import HeroSection from '@/components/general/HeroSection.vue';
import BenefitsSection from '@/components/general/BenefitsSection.vue';
</script>
<template>
  <HeaderMain />
  <HeroSection />
  <LogoSection />
  <TestimonialSection />
  <HowSection />
  <div class="container">
    <hr>
  </div>
  <BenefitsSection />
  <IntegrationSection />
  <CtaSection />
  <FooterSection />-
</template>

 
<script>


export default {
  name: "HomeView"
};
</script>     