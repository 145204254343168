<template>
  <section class="hero-section py-3 py-md-5">
    <div class="container">
      <div class="row">
        <div class="col-12 col-lg-6 pt-3 mb-5 mb-lg-0">
          <h1 class="site-headline font-weight-bold mb-3">Começe hoje a mudar o seu futuro.
          </h1>
          <div class="site-tagline mb-4">Desenhado para profissionais que procuram o topo da sua carreira, a Smart Learning redefine a excelência em formações em constante evolução.<br/>Formações em <span class="text-primary font-weight-bold"><vue-typewriter-effect :strings="['Programação', 'Data Science', 'Inteligência Artifical', 'Testes de Software', 'Cloud Computing', 'Matemática e Estatística', 'Línguas']" /></span>.</div>
          <div class="cta-btns mb-lg-3">
            <router-link class="btn btn-primary me-2 mb-3"  to="/contatos">Contate-nos<i
                class="fas fa-arrow-alt-circle-right ms-2"></i></router-link>
            <router-link class="btn btn-secondary mb-3"  to="areas">Consulte o catálogo<i
                class="fas fa-arrow-alt-circle-right ms-2"></i></router-link>
          </div>
        </div>
        <div class="col-12 col-lg-6 text-center">
          <img class="hero-figure mx-auto" :src="require(`@/assets/images/formacao-smart-learning.jpg`)" alt="">
        </div>
      </div><!--//row-->
    </div>
  </section><!--//hero-section-->
</template>

<script>
export default {
  name: "HeroSection",
  created() { },
  data() {
    return {};
  },
  props: {},
  methods: {},
};
</script>
